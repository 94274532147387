.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: whitesmoke;
    min-height: 100vh;
    overflow: hidden;
    padding: 20px 10px 40px 10px;
}


.card-container {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    margin: 1%;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    margin: 1%;
    width: 200px;
    height: 200px;
    padding: 20px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.10);
}

.link:hover, .link:active {
    border: 1px solid rgb(155, 155, 205) ;
    cursor: pointer;
    text-decoration: none;
}

.card h6 {
    font-family: 'Trebuchet MS', sans-serif;
    letter-spacing: 2px !important;
    color: rgb(64, 64, 64);
}

.card-value{
    font-size: 1em;
    color: var(--black);
}

.bg {
    font-size: 3em;
}

.small {
    font-size: 1.2em;
}

.card-body{
    font-size: 1em;
    color: rgb(99, 94, 94);
}

.refresh-button {
    border: none;
    border-radius: 7px;
    font-size: 18px;
    margin: 5px;
    height: 35px;
    width: 35px;
    background: none;
    color: var(--bg-secondary);
}

.refresh-button:hover {
    opacity: .7;
}

.refresh-button:active {
    opacity: 1;
}

/**ICONS - CARDS*/

.icon-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-style {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;
    min-height: 45px;
    min-width: 45px;
    box-shadow: 0px 1px 5px rgba(76, 78, 103, 0.2);

}

.orange {
    background-color: rgba(255, 128, 0, 0.1);
    color: rgba(255, 128, 0);
}

.red {
    background-color: rgba(249, 30, 1, 0.1);
    color: rgba(249, 30, 1);
}

.green {
    background-color: rgba(71, 178, 89, 0.1);
    color: rgba(71, 178, 89);
}

.blue {
    background-color: rgba(0, 13, 255, 0.1);
    color: rgb(47, 90, 244);

}

.yellow {
    background-color: rgba(255, 208, 0, 0.1);
    color: rgba(255, 208, 0);
}



.user-div-home {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    border-radius: 7px;
    display: none;
    background-color: var(--bg-main);
    padding: 10px;
    margin-top: -15px;
}

.username-home {
    font-size: 23px;
    font-weight: 900;
    margin-left: 20px;
    color: white;
}

.user-div-home .ico {
    font-size: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    color: whitesmoke;

}


@media screen and (max-width: 700px) {
    .user-div-home {
        display: flex;
    }
}


