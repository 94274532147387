.portal-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 200px;
}


.amplify-button--primary {
   background-color: var(--logo-sec) !important;
}


.portal-header {
    font-family: 'Trebuchet MS', sans-serif;
    color: var(--bg-main);
    letter-spacing: 5px !important;
    font-weight: 900;
    font-size: 2em;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
}

.form-header {
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: 900;
    color: rgb(50, 50, 50);
    padding: 10px 0;
}



.form-ico-div  {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}
  
.form-ico {
    font-size: 20px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -45px;
    color: #ffffff;
    backdrop-filter: blur(10px);
    border-radius: 50%;
    box-shadow: 1px 1px 10px rgba(60, 60, 60, 0.2);
}


@media screen and (max-width: 700px) {
    .portal-container {
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 60px;
    }
}


