:root {
	--bg-main:#8294db;
	--bg-secondary:#5468b7;
	--bg-light:#d7ddf3;
	--bg-comp:#d1a48e;
	--bg-bright: #7c94f1;
	--bg-dark: #525e8d;
	--black:rgb(64, 64, 64);
	--white:#f1f1f1;
	--logo-sec:#bf8a7c;
	--logo-prime:#a1826f;
}


.App {
	text-align: center;
}


* {
	box-sizing:border-box;
	-moz-box-sizing:border-box;
}

li {
	list-style: none;
}


body {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	padding: 0;
}

input, button, select {
	font-family: 'Montserrat', sans-serif;
	text-decoration: none;
	font-size: 14px;
	border: none;
	padding: 10px;
	margin: 3px;
	border-radius: 7px;
}


select {
	background: url('chevron-down-solid.svg') no-repeat 95% 50%;
    background-size: 10px 10px;
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
	outline: 0;
	padding-right: 25px;
	text-align: center;
}

 
h1, h2, h3, h4, h5, h6 {
	letter-spacing: 5px;
	font-weight: 900;
	font-family: 'Playfair display';
	text-transform: uppercase;
	margin: 0;
}

h1 {
	font-size: calc(2em + 3vw);
}

h2 {
	font-size: calc(2em + 2vw);
}

h3 {
	font-size: calc(2em + 1vw);
}

h4 {
	font-size: calc(2em + .5vw);
}

h5 {
	font-size: calc(1.2em);
}

h6 {
	font-size: calc(1em);
	font-family: 'Trebuchet MS', sans-serif;
    letter-spacing: 2px !important;
    color: rgb(64, 64, 64);
}

img {
	color: transparent;
	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}


.greeting {
	color: var(--bg-main);
}

.add {
	color: white;
	background-color: var(--bg-main);
}

.remove {
	color: white;
	background-color: #d46464;
}

.update {
	color: black;
	background-color: #efdb5d;
}


.redeem {
	background-color: rgb(209, 241, 30);
	color: var(--black);
}

.table-div {
	background-color: rgb(255, 255, 255);
	border-radius: 10px;
	width: 90%;
	height: 700px;
	overflow-y: scroll;
	overflow-x: scroll;
	box-shadow: 1px 1px 10px rgba(58, 73, 159, 0.15);
}

.label {
	font-size: x-small;
	border-radius: 3px;
	height: 20px;
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}
