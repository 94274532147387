.clientpool-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: whitesmoke;
    min-height: 100vh;
    overflow: hidden;
    padding: 20px 20px 40px 20px;
}

.clientpool-form-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0px;
}

.clientpool-form {
    box-shadow: 1px 1px 10px rgba(58, 73, 159, 0.3);
    min-width: 380px;
    width: 50%;
    text-align: left;
    padding: 20px;
    border-radius: 10px;

}

.clientpool-form input{
    border-radius: 7px;
    box-sizing: border-box;
    margin: 8px 0;
}

.clientpool-form input:hover{
    opacity: .7;
}

.clientpool-form input[type="text"], .clientpool-form input[type="tel"]{
    font-size: 1em;
    width: 100%;
}


.clientpool-form strong{
    color: #d46464;
}

.button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 10px;
}

.clientpool-form button[type="submit"]{
    border-radius: 7px;
    font-size: .9em;
    width: 30%;
}


.clientpool-form button[type="submit"]:active{
    opacity: .7;
}

.clientpool-form button[type="submit"]:disabled{
    opacity: .4;
    font-size: 1em;
}

.birthday-field {
    display: flex;
    justify-content: space-around;
    background-color: white;
    border-radius: 7px;
}

.clientpool-form input[type="number"] {
    width: auto;
    max-width: 100px;
    margin: 0 5px;
}

.clientpool-form label {
    margin: 0 10px;
}


.search-div {
    width: 90%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 10px 0;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgba(58, 73, 159, 0.15);
}


.search-div input[type="text"] {
    width: 91%;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.search-div i {
    width: 9%
}

.clientpool-form select {
    background-color: #ffffff;
    width: 50%;
}

.search-div button {
    width: 20%;
    background-color: var(--bg-main);
    color: white;
    min-width: fit-content;
}

.clientpool-form .form-ico {
    color: var(--bg-main);
    background-color: var(--bg-light);
}

hr {
    color: rgba(60, 60, 60, 0.3);
	border: .5px solid;
}

@media screen and (max-width: 700px) {
    .clientpool-form {
        width: 100%;
    }
    .button-group {
        flex-direction: column;
    }
    .clientpool-form button[type="submit"] {
        width: 100%;
        height: 50px;
        padding: 0;
    }
    input {
        font-size: 16px;
    }
    .search-div i {
        width: 20%
    }
    .search-div {
        width: 100%
    }
}


