.coupon-pool-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: whitesmoke;
    min-height: 100vh;
    overflow: hidden;
    padding: 20px 20px 40px 20px;
}

.coupon-pool-form-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0px;
}

.coupon-pool-form {
    box-shadow: 1px 1px 10px rgba(58, 73, 159, 0.3);
    min-width: 380px;
    width: 50%;
    text-align: left;
    padding: 20px;
    border-radius: 10px;
}

.coupon-pool-form input{
    border-radius: 7px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 8px 0;
    width: 100%;
}


.coupon-pool-form strong{
    color: #d46464;
}

.coupon-pool-form button[type="submit"]{
    margin-top: 10px;
    font-size: 1em;
    width: 50%;
}

.coupon-pool-form button[type="submit"]:hover{
    background-color: rgb(225, 247, 97);
}

.coupon-pool-form button[type="submit"]:disabled{
    opacity: .7;
    font-size: 1em;
}


.coupon-pool-container .fa-circle-check {
    color: rgb(247, 53, 15);
    font-size: 20px;
}

  
.coupon-pool-container .fa-circle-xmark {
    font-size: 20px;
    color: rgb(171, 181, 239);
}

.coupon-pool-container .fa-calendar-xmark {
    font-size: 20px;
    color: rgb(215, 29, 29);
}


.coupon-pool-form label {
    margin: 0 10px;
}

.coupon-pool-form .form-ico {
    color: rgb(192, 226, 0);
    background-color: rgb(243, 246, 228);
}

.coupon-pool-container select {
    background-color: #f3f3f3;
}

@media screen and (max-width: 700px) {
    .table-div {
        width: 100%;
    }
    .coupon-pool-form {
        width: 100%;
    }
    .coupon-pool-form button[type="submit"] {
        width: 100%;
        height: 50px;
        padding: 0;
    }
    input {
        font-size: 16px;
    }
}
