.sms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
  overflow: hidden;
  padding: 20px 20px 40px 20px;
}

.form-div {
  background-color: whitesmoke;
  width: 50%;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgb(187, 187, 187);
}

.form-div select {
  width: 100%;
  background-color: white;
}

.form-div input[type="tel"], .form-div textarea, .form-div button{
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  text-decoration: none;
}

.form-div label {
  width: 100%;
}


.form-div textarea {
  border: none;
  resize: none;
  text-decoration: none;
  outline:none;
}

.form-div button[type="submit"] {
  background-color: rgb(0, 0, 0);
  color: aliceblue;
}


.sms-form-radio {
  /* display : block; */
  display: flex;
  width: 100%;
}

.sms-form-radio label{ /* DIV STYLES */
  display: inline-block;
  background-color: #e9e9e9;
  padding: 10px 20px;
  margin: 1%;
  border-radius: 4px;
}

.sms-form-radio input[type="radio"]{ /* HIDE RADIO */
  opacity: 0;
  position: fixed;
  width: 0;
}

.sms-form-radio input[type="radio"]:hover + label{ /* (RADIO CHECKED) DIV STYLES */
  background-color: var(--bg-light);
  color: black;
}

.sms-form-radio input[type="radio"]:checked + label{ /* (RADIO CHECKED) DIV STYLES */
  background-color: var(--bg-bright);
  color: white;
}


@media screen and (max-width: 700px) {
  .sms-container {
      width: 100%;
  }
  .form-div {
      width: 100%;
  }
}
