.side-nav-container {
    position: fixed;
    background-color: none;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 200px;
    padding: 5px;
    border-radius: 7px;
    background-color: whitesmoke;
}


.side-nav-container nav {
    height: 100%;
    padding-top: 30px;
    background-color: var(--bg-main);
    border-radius: 7px;
    box-shadow: 1px 1px 10px rgba(75, 86, 157, 0.394);

}

.side-nav-ul {
    height: 100%;
    padding: 0;
    margin:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: auto;

}

.side-nav-item {
    background: none;
    color: rgba(255, 255, 255);
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    border: none;
    cursor: pointer;
    padding: 10px 10px;
    width: 90%;
    border-radius: 15px;
    margin: 5px;
}


.side-nav-item i {
    font-size: 20px;
    margin: 0 10px;
}

.side-nav-item:hover:not(.active){
    background-color: rgba(255, 255, 255, 0.15);
}

.side-nav-item.active {
    background-color: rgba(255, 255, 255, 0.4);
    color: rgb(53, 54, 73);
}

.signout {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.2);
    bottom: 0;
    color: white;
    margin-bottom: 30px; 
}

.user-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.username {
    font-size: 20px;
    font-weight: 900;
    color: white;
    margin-top: 10px;

}

.user-div .ico {
    font-size: 50px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
}



@media screen and (max-width: 700px) {
    .side-nav-container {
        position: fixed;
        z-index: 500;
        width: 100%;

        height: fit-content;
        top: unset;
        left: unset;
        bottom: 0;

    }
    .side-nav-container nav {
        padding: 0;
        height: fit-content;
    }

    .side-nav-ul{
        height: 100%;
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
    .side-nav-item {
        height: 70px;
        width: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        margin: 0 !important;
        box-shadow: none;
    }
    .side-nav-text{
        display: none;
    }
    .signout {
        border-radius: 7px;
        background-color: var(--black);
        position: unset;
    }
    .user-div {
        display: none;
    }
}


