body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
	--bg-main:#8294db;
	--bg-secondary:#5468b7;
	--bg-light:#d7ddf3;
	--bg-comp:#d1a48e;
	--bg-bright: #7c94f1;
	--bg-dark: #525e8d;
	--black:rgb(64, 64, 64);
	--white:#f1f1f1;
	--logo-sec:#bf8a7c;
	--logo-prime:#a1826f;
}


.App {
	text-align: center;
}


* {
	box-sizing:border-box;
	-moz-box-sizing:border-box;
}

li {
	list-style: none;
}


body {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	padding: 0;
}

input, button, select {
	font-family: 'Montserrat', sans-serif;
	text-decoration: none;
	font-size: 14px;
	border: none;
	padding: 10px;
	margin: 3px;
	border-radius: 7px;
}


select {
	background: url(/static/media/chevron-down-solid.e033a312.svg) no-repeat 95% 50%;
    background-size: 10px 10px; 
	-webkit-appearance: none; 
	appearance: none;
	outline: 0;
	padding-right: 25px;
	text-align: center;
}

 
h1, h2, h3, h4, h5, h6 {
	letter-spacing: 5px;
	font-weight: 900;
	font-family: 'Playfair display';
	text-transform: uppercase;
	margin: 0;
}

h1 {
	font-size: calc(2em + 3vw);
}

h2 {
	font-size: calc(2em + 2vw);
}

h3 {
	font-size: calc(2em + 1vw);
}

h4 {
	font-size: calc(2em + .5vw);
}

h5 {
	font-size: calc(1.2em);
}

h6 {
	font-size: calc(1em);
	font-family: 'Trebuchet MS', sans-serif;
    letter-spacing: 2px !important;
    color: rgb(64, 64, 64);
}

img {
	color: transparent;
	-webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
	user-select: none; /* Standard */
}


.greeting {
	color: #8294db;
	color: var(--bg-main);
}

.add {
	color: white;
	background-color: #8294db;
	background-color: var(--bg-main);
}

.remove {
	color: white;
	background-color: #d46464;
}

.update {
	color: black;
	background-color: #efdb5d;
}


.redeem {
	background-color: rgb(209, 241, 30);
	color: rgb(64, 64, 64);
	color: var(--black);
}

.table-div {
	background-color: rgb(255, 255, 255);
	border-radius: 10px;
	width: 90%;
	height: 700px;
	overflow-y: scroll;
	overflow-x: scroll;
	box-shadow: 1px 1px 10px rgba(58, 73, 159, 0.15);
}

.label {
	font-size: x-small;
	border-radius: 3px;
	height: 20px;
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.portal-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 200px;
}


.amplify-button--primary {
   background-color: var(--logo-sec) !important;
}


.portal-header {
    font-family: 'Trebuchet MS', sans-serif;
    color: var(--bg-main);
    letter-spacing: 5px !important;
    font-weight: 900;
    font-size: 2em;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
}

.form-header {
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: 900;
    color: rgb(50, 50, 50);
    padding: 10px 0;
}



.form-ico-div  {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}
  
.form-ico {
    font-size: 20px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -45px;
    color: #ffffff;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border-radius: 50%;
    box-shadow: 1px 1px 10px rgba(60, 60, 60, 0.2);
}


@media screen and (max-width: 700px) {
    .portal-container {
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 60px;
    }
}



.side-nav-container {
    position: fixed;
    background-color: none;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 200px;
    padding: 5px;
    border-radius: 7px;
    background-color: whitesmoke;
}


.side-nav-container nav {
    height: 100%;
    padding-top: 30px;
    background-color: var(--bg-main);
    border-radius: 7px;
    box-shadow: 1px 1px 10px rgba(75, 86, 157, 0.394);

}

.side-nav-ul {
    height: 100%;
    padding: 0;
    margin:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: auto;

}

.side-nav-item {
    background: none;
    color: rgba(255, 255, 255);
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    border: none;
    cursor: pointer;
    padding: 10px 10px;
    width: 90%;
    border-radius: 15px;
    margin: 5px;
}


.side-nav-item i {
    font-size: 20px;
    margin: 0 10px;
}

.side-nav-item:hover:not(.active){
    background-color: rgba(255, 255, 255, 0.15);
}

.side-nav-item.active {
    background-color: rgba(255, 255, 255, 0.4);
    color: rgb(53, 54, 73);
}

.signout {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.2);
    bottom: 0;
    color: white;
    margin-bottom: 30px; 
}

.user-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.username {
    font-size: 20px;
    font-weight: 900;
    color: white;
    margin-top: 10px;

}

.user-div .ico {
    font-size: 50px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
}



@media screen and (max-width: 700px) {
    .side-nav-container {
        position: fixed;
        z-index: 500;
        width: 100%;

        height: -webkit-fit-content;

        height: -moz-fit-content;

        height: fit-content;
        top: unset;
        left: unset;
        bottom: 0;

    }
    .side-nav-container nav {
        padding: 0;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .side-nav-ul{
        height: 100%;
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
    .side-nav-item {
        height: 70px;
        width: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        margin: 0 !important;
        box-shadow: none;
    }
    .side-nav-text{
        display: none;
    }
    .signout {
        border-radius: 7px;
        background-color: var(--black);
        position: unset;
    }
    .user-div {
        display: none;
    }
}



.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: whitesmoke;
    min-height: 100vh;
    overflow: hidden;
    padding: 20px 10px 40px 10px;
}


.card-container {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    margin: 1%;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    margin: 1%;
    width: 200px;
    height: 200px;
    padding: 20px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.10);
}

.link:hover, .link:active {
    border: 1px solid rgb(155, 155, 205) ;
    cursor: pointer;
    text-decoration: none;
}

.card h6 {
    font-family: 'Trebuchet MS', sans-serif;
    letter-spacing: 2px !important;
    color: rgb(64, 64, 64);
}

.card-value{
    font-size: 1em;
    color: var(--black);
}

.bg {
    font-size: 3em;
}

.small {
    font-size: 1.2em;
}

.card-body{
    font-size: 1em;
    color: rgb(99, 94, 94);
}

.refresh-button {
    border: none;
    border-radius: 7px;
    font-size: 18px;
    margin: 5px;
    height: 35px;
    width: 35px;
    background: none;
    color: var(--bg-secondary);
}

.refresh-button:hover {
    opacity: .7;
}

.refresh-button:active {
    opacity: 1;
}

/**ICONS - CARDS*/

.icon-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-style {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;
    min-height: 45px;
    min-width: 45px;
    box-shadow: 0px 1px 5px rgba(76, 78, 103, 0.2);

}

.orange {
    background-color: rgba(255, 128, 0, 0.1);
    color: rgba(255, 128, 0);
}

.red {
    background-color: rgba(249, 30, 1, 0.1);
    color: rgba(249, 30, 1);
}

.green {
    background-color: rgba(71, 178, 89, 0.1);
    color: rgba(71, 178, 89);
}

.blue {
    background-color: rgba(0, 13, 255, 0.1);
    color: rgb(47, 90, 244);

}

.yellow {
    background-color: rgba(255, 208, 0, 0.1);
    color: rgba(255, 208, 0);
}



.user-div-home {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    border-radius: 7px;
    display: none;
    background-color: var(--bg-main);
    padding: 10px;
    margin-top: -15px;
}

.username-home {
    font-size: 23px;
    font-weight: 900;
    margin-left: 20px;
    color: white;
}

.user-div-home .ico {
    font-size: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    color: whitesmoke;

}


@media screen and (max-width: 700px) {
    .user-div-home {
        display: flex;
    }
}



.clientpool-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: whitesmoke;
    min-height: 100vh;
    overflow: hidden;
    padding: 20px 20px 40px 20px;
}

.clientpool-form-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0px;
}

.clientpool-form {
    box-shadow: 1px 1px 10px rgba(58, 73, 159, 0.3);
    min-width: 380px;
    width: 50%;
    text-align: left;
    padding: 20px;
    border-radius: 10px;

}

.clientpool-form input{
    border-radius: 7px;
    box-sizing: border-box;
    margin: 8px 0;
}

.clientpool-form input:hover{
    opacity: .7;
}

.clientpool-form input[type="text"], .clientpool-form input[type="tel"]{
    font-size: 1em;
    width: 100%;
}


.clientpool-form strong{
    color: #d46464;
}

.button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 10px;
}

.clientpool-form button[type="submit"]{
    border-radius: 7px;
    font-size: .9em;
    width: 30%;
}


.clientpool-form button[type="submit"]:active{
    opacity: .7;
}

.clientpool-form button[type="submit"]:disabled{
    opacity: .4;
    font-size: 1em;
}

.birthday-field {
    display: flex;
    justify-content: space-around;
    background-color: white;
    border-radius: 7px;
}

.clientpool-form input[type="number"] {
    width: auto;
    max-width: 100px;
    margin: 0 5px;
}

.clientpool-form label {
    margin: 0 10px;
}


.search-div {
    width: 90%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 10px 0;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgba(58, 73, 159, 0.15);
}


.search-div input[type="text"] {
    width: 91%;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.search-div i {
    width: 9%
}

.clientpool-form select {
    background-color: #ffffff;
    width: 50%;
}

.search-div button {
    width: 20%;
    background-color: var(--bg-main);
    color: white;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.clientpool-form .form-ico {
    color: var(--bg-main);
    background-color: var(--bg-light);
}

hr {
    color: rgba(60, 60, 60, 0.3);
	border: .5px solid;
}

@media screen and (max-width: 700px) {
    .clientpool-form {
        width: 100%;
    }
    .button-group {
        flex-direction: column;
    }
    .clientpool-form button[type="submit"] {
        width: 100%;
        height: 50px;
        padding: 0;
    }
    input {
        font-size: 16px;
    }
    .search-div i {
        width: 20%
    }
    .search-div {
        width: 100%
    }
}




.Dtable {
    border-collapse: collapse;
    background-color: rgb(255, 255, 255);
    width: 100%;
    font-size: .9em;
}


.Dtable tr:nth-child(odd) {
    background: rgb(243, 244, 255);
}


.Dtable tr, td {
    max-height: 50px;
}


.Dtable th {
    text-transform:capitalize;
    position: sticky;
    top:0;
    position: -webkit-sticky;
    background-color: var(--bg-main) !important;
    color: white;
    height: 75px;
}

th, td, tr {
    padding: 10px;
}

.fa-circle-check {
    color: rgb(140, 194, 140);
    font-size: 20px;
}

  
.fa-circle-xmark {
    font-size: 20px;
    color: rgb(237, 86, 86);
}

.noMatch {
    font-size: 18px;
    width: 100%;
    padding-top: 30px;
}


.ok {
	background-color: rgb(217, 228, 237);
	color: rgb(82, 82, 82);
}

.expire {
	background-color: #ed5656;
	color:white;
}

.codeStyle {
    border: 1px dashed rgb(139, 130, 130);
    background-color: white;
    width: 100%;
    height: 25px;
    padding: 10px;
    font-size: 1.1em;
    color: rgb(144, 133, 133);
    font-weight: bolder;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.expandingButton {
    background: none;
    font-size: 15px;
    color: var(--black);
}

.expandingDiv {
    display: flex;
    flex-direction: row;  
    justify-content: left;
    align-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.expandingContent {
    width: 0;
    max-height: 45px;
    overflow-x: scroll;
}
.promo-pool-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: whitesmoke;
    min-height: 100vh;
    overflow: hidden;
    padding: 20px 20px 40px 20px;
}

.promo-pool-form-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0px;
}

.promo-pool-form {
    box-shadow: 1px 1px 10px rgba(58, 73, 159, 0.3);
    min-width: 380px;
    width: 50%;
    text-align: left;
    padding: 20px;
    border-radius: 10px;

}

.promo-pool-form input{
    border-radius: 7px;
    box-sizing: border-box;
    margin: 8px 0;

}

.promo-pool-form input:hover{
    opacity: .7;
}


.promo-pool-form input[type="text"], .promo-pool-form input[type="tel"]{
    font-size: 1em;
    width: 100%;
}

.promo-pool-form textarea {
    resize: none;
    width: 100%;
    border: none;
    border-radius: 7px;
    box-sizing: border-box;
    margin: 8px 0;
}


.promo-pool-form strong{
    color: #d46464;
}

.promo-button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.promo-pool-form input[type="submit"]{
    font-size: .9em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.promo-pool-form input[type="submit"]:active{
    opacity: 1;
}


.promo-pool-form label {
    margin: 0 10px;
}

fieldset
{
    padding: 10px;
    border: none;
}

legend
{
    padding: 0 10px;
}

.promo-pool-form .form-ico {
    color: coral;
    background-color: rgb(251, 243, 240);
}

@media screen and (max-width: 700px) {
    .table-div {
        width: 100%;
    }
    .promo-pool-form {
        width: 100%;
    }
    .button-group {
        flex-direction: column;
    }
    .promo-pool-form input[type="submit"] {
        width: 100%;
        height: 50px;
        padding: 0;
    }
    input {
        font-size: 16px;
    }
}

.coupon-pool-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: whitesmoke;
    min-height: 100vh;
    overflow: hidden;
    padding: 20px 20px 40px 20px;
}

.coupon-pool-form-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0px;
}

.coupon-pool-form {
    box-shadow: 1px 1px 10px rgba(58, 73, 159, 0.3);
    min-width: 380px;
    width: 50%;
    text-align: left;
    padding: 20px;
    border-radius: 10px;
}

.coupon-pool-form input{
    border-radius: 7px;
    box-sizing: border-box;
    margin: 8px 0;
    width: 100%;
}


.coupon-pool-form strong{
    color: #d46464;
}

.coupon-pool-form button[type="submit"]{
    margin-top: 10px;
    font-size: 1em;
    width: 50%;
}

.coupon-pool-form button[type="submit"]:hover{
    background-color: rgb(225, 247, 97);
}

.coupon-pool-form button[type="submit"]:disabled{
    opacity: .7;
    font-size: 1em;
}


.coupon-pool-container .fa-circle-check {
    color: rgb(247, 53, 15);
    font-size: 20px;
}

  
.coupon-pool-container .fa-circle-xmark {
    font-size: 20px;
    color: rgb(171, 181, 239);
}

.coupon-pool-container .fa-calendar-xmark {
    font-size: 20px;
    color: rgb(215, 29, 29);
}


.coupon-pool-form label {
    margin: 0 10px;
}

.coupon-pool-form .form-ico {
    color: rgb(192, 226, 0);
    background-color: rgb(243, 246, 228);
}

.coupon-pool-container select {
    background-color: #f3f3f3;
}

@media screen and (max-width: 700px) {
    .table-div {
        width: 100%;
    }
    .coupon-pool-form {
        width: 100%;
    }
    .coupon-pool-form button[type="submit"] {
        width: 100%;
        height: 50px;
        padding: 0;
    }
    input {
        font-size: 16px;
    }
}

.sms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
  overflow: hidden;
  padding: 20px 20px 40px 20px;
}

.form-div {
  background-color: whitesmoke;
  width: 50%;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgb(187, 187, 187);
}

.form-div select {
  width: 100%;
  background-color: white;
}

.form-div input[type="tel"], .form-div textarea, .form-div button{
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  text-decoration: none;
}

.form-div label {
  width: 100%;
}


.form-div textarea {
  border: none;
  resize: none;
  text-decoration: none;
  outline:none;
}

.form-div button[type="submit"] {
  background-color: rgb(0, 0, 0);
  color: aliceblue;
}


.sms-form-radio {
  /* display : block; */
  display: flex;
  width: 100%;
}

.sms-form-radio label{ /* DIV STYLES */
  display: inline-block;
  background-color: #e9e9e9;
  padding: 10px 20px;
  margin: 1%;
  border-radius: 4px;
}

.sms-form-radio input[type="radio"]{ /* HIDE RADIO */
  opacity: 0;
  position: fixed;
  width: 0;
}

.sms-form-radio input[type="radio"]:hover + label{ /* (RADIO CHECKED) DIV STYLES */
  background-color: var(--bg-light);
  color: black;
}

.sms-form-radio input[type="radio"]:checked + label{ /* (RADIO CHECKED) DIV STYLES */
  background-color: var(--bg-bright);
  color: white;
}


@media screen and (max-width: 700px) {
  .sms-container {
      width: 100%;
  }
  .form-div {
      width: 100%;
  }
}

