.promo-pool-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: whitesmoke;
    min-height: 100vh;
    overflow: hidden;
    padding: 20px 20px 40px 20px;
}

.promo-pool-form-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0px;
}

.promo-pool-form {
    box-shadow: 1px 1px 10px rgba(58, 73, 159, 0.3);
    min-width: 380px;
    width: 50%;
    text-align: left;
    padding: 20px;
    border-radius: 10px;

}

.promo-pool-form input{
    border-radius: 7px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 8px 0;

}

.promo-pool-form input:hover{
    opacity: .7;
}


.promo-pool-form input[type="text"], .promo-pool-form input[type="tel"]{
    font-size: 1em;
    width: 100%;
}

.promo-pool-form textarea {
    resize: none;
    width: 100%;
    border: none;
    border-radius: 7px;
    box-sizing: border-box;
    margin: 8px 0;
}


.promo-pool-form strong{
    color: #d46464;
}

.promo-button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.promo-pool-form input[type="submit"]{
    font-size: .9em;
    width: fit-content;
}

.promo-pool-form input[type="submit"]:active{
    opacity: 1;
}


.promo-pool-form label {
    margin: 0 10px;
}

fieldset
{
    padding: 10px;
    border: none;
}

legend
{
    padding: 0 10px;
}

.promo-pool-form .form-ico {
    color: coral;
    background-color: rgb(251, 243, 240);
}

@media screen and (max-width: 700px) {
    .table-div {
        width: 100%;
    }
    .promo-pool-form {
        width: 100%;
    }
    .button-group {
        flex-direction: column;
    }
    .promo-pool-form input[type="submit"] {
        width: 100%;
        height: 50px;
        padding: 0;
    }
    input {
        font-size: 16px;
    }
}
