
.Dtable {
    border-collapse: collapse;
    background-color: rgb(255, 255, 255);
    width: 100%;
    font-size: .9em;
}


.Dtable tr:nth-child(odd) {
    background: rgb(243, 244, 255);
}


.Dtable tr, td {
    max-height: 50px;
}


.Dtable th {
    text-transform:capitalize;
    position: sticky;
    top:0;
    position: -webkit-sticky;
    background-color: var(--bg-main) !important;
    color: white;
    height: 75px;
}

th, td, tr {
    padding: 10px;
}

.fa-circle-check {
    color: rgb(140, 194, 140);
    font-size: 20px;
}

  
.fa-circle-xmark {
    font-size: 20px;
    color: rgb(237, 86, 86);
}

.noMatch {
    font-size: 18px;
    width: 100%;
    padding-top: 30px;
}


.ok {
	background-color: rgb(217, 228, 237);
	color: rgb(82, 82, 82);
}

.expire {
	background-color: #ed5656;
	color:white;
}

.codeStyle {
    border: 1px dashed rgb(139, 130, 130);
    background-color: white;
    width: 100%;
    height: 25px;
    padding: 10px;
    font-size: 1.1em;
    color: rgb(144, 133, 133);
    font-weight: bolder;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.expandingButton {
    background: none;
    font-size: 15px;
    color: var(--black);
}

.expandingDiv {
    display: flex;
    flex-direction: row;  
    justify-content: left;
    align-content: center;
    width: fit-content;
}

.expandingContent {
    width: 0;
    max-height: 45px;
    overflow-x: scroll;
}